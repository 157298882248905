import { Project } from '../models/models';
import hackernews from './hacker-news-logo.png'
import producthunt from './product-hunt-logo.png'
import './startup.css';

const StartupComponent = (startup: Project) => {
    return (
        <div className="align-items-stretch mt-4">
            <div className="startup-item">
                <div className="startup-content">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h2>{ startup.pk }</h2>
                        <a className="btn btn-success" href={ "https://shareasale.com/r.cfm?b=467188&u=998403&m=46483&urllink=www.namecheap.com/domains/registration/results.aspx?domain=" + startup.pk + "&afftrack=zombiestartup.com" } target="_blank" rel="noopener noreferrer nofollow">Buy domain</a>
                    </div>
                    { startup.producthunt
                      ? 
                        <div className="project d-flex justify-content-between align-items-center">
                            <div className="project-profile d-flex align-items-center">
                                <img src={ producthunt } className="img-fluid" alt="Product Hunt Logo" />
                                <span>
                                    <a href={ "https://www.producthunt.com/posts/" + startup.producthunt.ref } target="_blank" rel="noopener noreferrer nofollow">
                                        { startup.producthunt.title }
                                    </a>
                                </span>
                            </div>
                            <div className="project-rank d-flex align-items-center">
                                <i className="bx bx-upvote"></i>&nbsp; { startup.producthunt.points }
                            </div>
                        </div>
                    : null }

                    { startup.hackernews
                      ?
                        <div className="project d-flex justify-content-between align-items-center">
                            <div className="project-profile d-flex align-items-center">
                                <img src={ hackernews } className="img-fluid" alt="YCombinator - Hacker News Logo" />
                                <span>
                                    <a href={ startup.hackernews.link } target="_blank" rel="noopener noreferrer nofollow">
                                        { startup.hackernews.title }
                                    </a>
                                </span>
                            </div>
                            <div className="project-rank d-flex align-items-center">
                                <i className="bx bx-upvote"></i>&nbsp; { startup?.hackernews?.points }
                            </div>
                        </div>
                    : null }
                </div>
            </div>
        </div>
    );
}

export default StartupComponent;