import ContainerComponent from '../components/ContainerComponent';
import StartupComponent from '../components/StartupComponent';
import { Project } from '../models/models';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './home.css'

const BASE_URL = 'https://api.zombiestartup.com'

function HomePage() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [nextPage, setNextPage] = useState('');
  const [page, setPage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    const loadProjects = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${BASE_URL}?next=${page}`);

        setProjects((projects) => [...projects, ...response.data.projects]);
        setNextPage(response.data.next)
        setErrorMsg('');
      } catch (error) {
        setErrorMsg('Error while loading data. Try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    loadProjects();
  }, [page]);

  const loadMore = (next: string) => {
    if(next !== undefined) {
      setPage(next);
    }
  };

  return (
    <ContainerComponent>
      <section id="hero" className="d-flex justify-content-center align-items-center">
        <div className="container position-relative">
          <h1>Now is your chance to bring a startup back to life!</h1>
          <h2>The entrepreneurial virus has infected many people across the world. This virus has led people to drop out of school or leave their cozy corporate job to start their own company. Unfortunately, most companies do not survive and those infected with the entrepreneurial virus move on to their next startup.</h2>
        </div>
      </section>

      <section id="popular-startups" className="startups">
        <div className="container">

          <div className="row justify-content-center">
            <div className="col-2 d-none d-lg-block">
              <h4>Data Sources</h4>
              <div className="data-source mb-4">
                <div className="d-flex mb-2">
                  <button type="button" className="btn btn-outline-secondary">Product Hunt</button>
                </div>

                <div className="d-flex mb-2">
                  <button type="button" className="btn btn-outline-secondary">Hacker News</button>
                </div>

                <div className="d-flex mb-2">
                  <button type="button" className="btn btn-outline-secondary">Reddit</button>
                </div>
              </div>


              <h4>TLDs</h4>
              <div className="d-flex flex-wrap">
                <button type="button" className="m-1 btn btn-outline-secondary">com</button>
                <button type="button" className="m-1 btn btn-outline-secondary">co</button>
                <button type="button" className="m-1  btn btn-outline-secondary">se</button>
                <button type="button" className="m-1  btn btn-outline-secondary">co.uk</button>
                <button type="button" className="m-1  btn btn-outline-secondary">in</button>
                <button type="button" className="m-1  btn btn-outline-secondary">net</button>
                <button type="button" className="m-1  btn btn-outline-secondary">cloud</button>
                <button type="button" className="m-1  btn btn-outline-secondary">app</button>
                <button type="button" className="m-1  btn btn-outline-secondary">dev</button>
                <button type="button" className="m-1  btn btn-outline-secondary">me</button>
                <button type="button" className="m-1  btn btn-outline-secondary">it</button>
              </div>
            </div>


            <div className="col-lg-8 col-md-6">
              {projects
              ? projects.map((project) => {
                  return <StartupComponent key={ project.pk } { ...project } />
                })
              : null}

              <div className="d-grid gap-2 col-3 mx-auto mt-5 mb-5">
                <button type="button" className="row btn btn-lg btn-outline-secondary" onClick={() => loadMore(nextPage)}>Load more</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ContainerComponent>
  );
}

export default HomePage;
