import { Link } from "react-router-dom";
import './header.css';

function HeaderComponent() {
    return (
        <header id="header" className="fixed-top">
            <div className="container d-flex align-items-center">
                <h1 className="logo me-auto"><Link to="/">Zombie Startup</Link></h1>
            </div>
        </header>
    );
}

export default HeaderComponent;