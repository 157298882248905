import { ReactNode } from "react";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";

type ContainerComponentProps = {
    children: ReactNode;
};

const ContainerComponent = ({ children }: ContainerComponentProps) => {
    return (
        <>
          <HeaderComponent />
          {children}
          <FooterComponent />
        </>
      );
}

export default ContainerComponent;