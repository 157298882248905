import "./footer.css"

function FooterComponent() {
    return (
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 footer-contact">
                            <h3>An idea born.</h3>
                            <p>The idea for Zombie Startup came about during a Techcrunch hackathon in 2013. Zombie Startup was rebuilt in 2022. Data is refreshed several times a week.</p>
                            <br />
                            <p>Be responsible if you are going to let the domain of your project or startup expire.</p>
                            <br />
                            <p>Please reach out if you have any ideas or feedback to improve Zombie Startup.</p>
                        </div>
                    
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Built With</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i>AWS CDK</li>
                                <li><i className="bx bx-chevron-right"></i>AWS Lambda</li>
                                <li><i className="bx bx-chevron-right"></i>AWS DynamoDB</li>
                                <li><i className="bx bx-chevron-right"></i>React</li>
                                <li><i className="bx bx-chevron-right"></i>Bootstrap</li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Data Sources</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <a href="https://api.producthunt.com/v2/docs/" target="_blank" rel="noopener noreferrer">Product Hunt</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="https://hn.algolia.com/api/" target="_blank" rel="noopener noreferrer">Hacker News</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="https://www.reddit.com/dev/api/" target="_blank" rel="noopener noreferrer">Reddit</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a href="https://whoislookup.dev" target="_blank" rel="noopener noreferrer">Domain Availability API</a></li>
                                <li><i className="bx bx-chevron-right"></i>More to come...</li>
                            </ul>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 footer-newsletter">
                            <h4>Join Our Newsletter</h4>
                            <p>Get notified of new Zombie Startups so you can revive it!</p>
                            <form action="" method="post">
                                <input type="email" name="email" /><input type="submit" value="Subscribe" />
                            </form>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="container d-md-flex py-4">
                <div className="me-md-auto text-center text-md-start">
                <div className="credits">
                    Built by <a href="https://michaelcarrano.com" target="_blank" rel="noopener noreferrer">Michael Carrano</a>.
                </div>
                </div>
                <div className="social-links text-center text-md-right pt-3 pt-md-0">
                    <a href="https://michaelcarrano.com" target="_blank" rel="noopener noreferrer" className="instagram"><i className="bx bx-link"></i></a>
                    <a href="https://github.com/michaelcarrano" target="_blank" rel="noopener noreferrer" className="google-plus"><i className="bx bxl-github"></i></a>
                    <a href="https://linkedin.com/in/carranom" target="_blank" rel="noopener noreferrer" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                </div>
            </div>
        </footer>
    );
}

export default FooterComponent;